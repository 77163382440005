import React, { useState, useEffect } from "react";
import { Box, Drawer, Typography, Link, Grid, IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import CallIcon from "@mui/icons-material/CallOutlined";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { GetDrawerWidth, ActionIconStyle } from "../utils/AAStyles";

function AAHelpDrawer(props) {
  const theme = useTheme();

  const [isOpen] = useState(props.open);

  const SubTitle = (title) =>
  {
    return (
     <Typography align="left" variant="subtitle1" sx={{color: "#797979", fontWeight: 600}}>
      {title}
    </Typography>
  )};

  const BodyText = (text) =>
  {
    return (
     <Typography align="left" variant="body2" sx={{color: "#535353"}}>
      {text}
    </Typography>
  )};

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const copyMetadataInfo = (event) => {
    event.stopPropagation();

    let copyString = `Idox Security Admin API Metadata Information\n`;
    copyString += `============================================\n`;
    copyString += `\n`;
    copyString += `URL: ${process.env.REACT_APP_SECURITY_API}`;
    copyString += `Version: ${process.env.REACT_APP_SECURITY_VERSION}`;

    copyTextToClipboard(copyString);
  };
 
  
  
  return (
    <Drawer
      sx={{ 
        width: isOpen ? GetDrawerWidth() : 0,
        anchor: "right",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isOpen ? GetDrawerWidth() : 0,
        },
      }}
      variant="persistent"
      anchor="right"
      open={isOpen}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: theme.spacing(1, 1),
          // necessary for content to be below app bar
          ...theme.mixins.toolbar,
          justifyContent: "flex-start",
        }}
      >
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" noWrap sx={{ color: "#797979", padding: theme.spacing(1, 1) }}>
              Help and support
            </Typography>
          </Grid>
          <Grid item sx={{padding: theme.spacing(1, 1)}}>
              <IconButton aria-label="Close" onClick={props.handleDrawerClose} size="large"  >
              <CloseIcon variant="close" />
              </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container direction="column" justifyContent="center" alignItems="flex-start" sx={{paddingLeft: theme.spacing(2)}}>
        <Grid item xs={12} sx={{ paddingTop: theme.spacing(2), }}>
            {SubTitle("Idox Security Admin")}
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: theme.spacing(1), marginRight:theme.spacing(3) }}>
            {BodyText("Version 2.070324")}
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: theme.spacing(3),paddingBottom: theme.spacing(3), marginRight:theme.spacing(3) }}>
          </Grid>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ paddingTop: theme.spacing(1), }}>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: theme.spacing(6), }}>
            {SubTitle("General Enquiries")}
          </Grid>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ paddingTop: theme.spacing(1)}}>
            <Grid item xs={1}>
              <EmailIcon sx={{ color: "#535353", display: "inline-flex"}} />
            </Grid>
            <Grid item sx={{ display: "inline-flex"}}>
              <Link align={"left"}
                href="mailto:ads_solutions@idoxgroup.com?subject=Solutions enquiry raised from Idox Security Admin"
                variant="body2"
              >
                ads_solutions@idoxgroup.com
              </Link>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={1}>
              <CallIcon sx={{ color: "#535353", display: "inline-flex" }}/>
            </Grid>
            <Grid item>
              {BodyText("+44 (0) 1483 717 950")}
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: theme.spacing(1) }}>
            {BodyText("Idox Group")}
            {BodyText("Unit 5 Woking 8")}
            {BodyText("Forsyth Road")}
            {BodyText("Woking")}
            {BodyText("Surrey")}
            {BodyText("GU21 5SB")}
          </Grid>
          <Grid item xs={12}  sx={{paddingTop: theme.spacing(2)}}>
            {SubTitle("API Metadata information")}
          </Grid>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={12}>
              {BodyText(`URL: ${process.env.REACT_APP_SECURITY_API}`)}
              {BodyText(`Version: ${process.env.REACT_APP_SECURITY_VERSION}`)}
            </Grid>

            <Grid item xs={1}>
              <IconButton onClick={(event) => copyMetadataInfo(event)} size="small">
                <ContentCopyIcon sx={ActionIconStyle()} />
              </IconButton>
            </Grid>
            <Grid
              item
              sx={{
                display: "inline-flex",
              }}
            >
              <Link component="button" align={"left"} variant="body2" onClick={(event) => copyMetadataInfo(event)}>
                Copy metadata information
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}

export default AAHelpDrawer;
